.productbox_Container {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  text-decoration: none;
  min-width: 150px;
  min-height: 190px;
  position: relative;
}

.bg_blue {
  background: #d1f4ff;
}

.bg_pink {
  background: #ffd1d1;
}

.carousel_card {
  min-width: 150px;
  min-height: 250px;
}

.productbox_Container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.productbox_Container img {
  max-width: 165px;
}

.grid_card {
  max-width: 158px;
  max-height: 158px;
  min-height: 150px;
  min-width: 150px;
}

.carousel_card img {
  min-width: 140px;
  min-height: 140px;
  /* max-width: 140px; */
  max-height: 140px;
}

.productbox_details {
  background: #ffffff;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.375rem;
  gap: 0.125rem;
  max-width: 118px;
  /* max-height: 66px; */
  height: 100%;
  width: 100%;
  position: absolute;
  height: fit-content;
  bottom: -10px;
}

/* .carousel_card .productbox_details{
  transform: translate(0, 10px);
} */

.grid_card .productbox_details {
  /* transform: translate(0, -28px); */
  bottom: -46px;
}

.box_heading {
  letter-spacing: 0.015em;
  font-variant: small-caps;
  color: #6d747a;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1rem;
  margin: 0;
}

.box_price {
  letter-spacing: -0.015em;
  color: #08090a;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
}

.box_itemName {
  letter-spacing: 0.004em;
  color: #6d747a;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0 0.375rem;
  text-align: center;
  max-height: 60px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 768px) {
  .productbox_Container {
    min-width: 210px;
    min-height: 260px;
  }
  .carousel_card {
    min-width: 210px;
    min-height: 296px;
  }

  .grid_card {
    max-width: 210px;
    max-height: 230px;
  }

  .carousel_card img {
    /* max-width: 110px; */
    max-height: 168px;
    min-width: 110px;
    min-height: 168px;
  }

  .productbox_details {
    max-width: 178px;
    /* max-height: 92px; */
  }

  .carousel_card .productbox_details {
    transform: translate(0, 24px);
  }

  .grid_card .productbox_details {
    max-width: 178px;
  }

  .box_heading {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .box_price {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .box_itemName {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.01em;
  }
}

@media screen and (min-width: 1024px) {
  .productbox_Container {
    padding: 0.75rem 1.25rem 0;
  }

  .carousel_card {
    min-width: 312px;
    min-height: 350px;
  }

  .grid_card {
    max-width: 312px;
    max-height: 284px;
  }

  .carousel_card img {
    /* max-width: 135px; */
    max-height: 224px;
    min-height: 224px;
  }

  .grid_card .productbox_details {
    max-width: 264px;
    /* max-height: 108px; */
    padding: 0.5rem 0;
  }

  .carousel_card .productbox_details {
    /* transform: translate(0, 12px); */
    max-width: 264px;
  }

  .box_heading {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .box_price {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .box_itemName {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
