.top-scroll-banners-section {
  background-color: rgb(223, 227, 237);
  padding: 20px 10px;
  font-size: 20px;
  width: 100%;
  color: black !important;
  display: flex;
  gap: 20px;
  align-items: center;
}

.home-page-section-body {
  background-color: rgb(223, 227, 237);
  width: 100%;
  min-width: auto;
}
.home-page-section-body p {
  margin: 0;
}

.home-page-section-aside {
  display: flex;
  padding: 0 10px;
  background-color: rgb(223, 227, 237);
  flex-direction: column;
  justify-content: space-around;
}
