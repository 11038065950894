.storebox_Container{
  padding: 1rem;
}

.storebox_Wrapper{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.open_Store_button{
  text-decoration: none;
}

.store_Name, .store_Address, .store_Timing p, .store_Contact a, .store_Distance p, .store_Status p, .store_Map_Link p, .open_Store_button p{
  margin: 0;
  color: #08090A;
}

.store_Contact a{
  text-transform: none;
  text-decoration: none;
}

.store_Name{
  letter-spacing: -0.01em;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.store_Address, .store_Timing p, .store_Contact a, .store_Distance p, .store_Map_Link p, .open_Store_button p{
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #08090A;
}

.store_Address{
  max-width: 585px;
}

.store_Contact > a{
  color: #3D5176;
  border-bottom: 1px solid #1B325E;
}

.store_Contact > a:hover{
  color: #3D5176;
  border-bottom: 1px solid #1B325E;
}

.store_Map_Link p{
  color: #1B325E;
  border-bottom: 1px solid #1B325E;
}

.store_Map_Link{
  text-decoration: none;
}

.store_Timing, .store_Contact, .store_Distance, .store_Map_Link{
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.store_Status{
  padding: 0.125rem 0.375rem;
  border: 1px solid #3F8F00;
  border-radius: 0.25rem;
}

.store_Status p{
  color: #3F8F00;
  letter-spacing: 0.004em;
  font-weight: 600;
  font-size: 0.63rem;
  line-height: 0.875rem;
  text-transform: uppercase;
}

.open_Store_button{
  /* margin: 0 0.75rem; */
  padding: 1rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border-top: 1px solid #EBEDF0;
}

@media screen and (min-width: 768px) {
  .tab_Border{
    border-radius: 0.25rem;
    border: 1px solid #DDE0E7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  }

  .store_Name{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .store_Address, 
  .store_Timing p 
  .store_Contact a,
  .store_Distance p,
  .store_Map_Link p,
  .open_Store_button p{
    font-size: 1rem;
    line-height: 1.5rem;
  }
}