/* .App {
  display: flex;
  width: 100%;
  align-items: stretch;
} */

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700";

.DashboardWrapper {
  display: flex !important;
  width: 100%;
  align-items: stretch;
}

.dashboard_Whole_Wrapper {
  /*font-family: 'Poppins', sans-serif;*/
  font-family: "Nunito Sans", sans-serif;
  /*background: #fafafa;*/
  background-color: #f5f9fc;
  min-width: 100%;
}

p.ml-3 {
  margin-left: 1rem !important;
}

/* a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
} */

/* a tag */
.dash_sidebar_a,
.dash_sidebar_a:hover {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.dash_sidebar_a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #354052;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
  position: fixed !important;

  left: 0;
  height: -webkit-fill-available;
  z-index: 1;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  position: relative;
  overflow-y: auto;
}

.sidebar-header {
  background: #1b325e;
  color: #adb5bd;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: 0.2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header>button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.content {
  padding: 20px;
  min-height: 100vh;
}

.content.is-open {
  margin-left: 250px;
}

@media only screen and (max-width: 575.98px) {
  .DashboardWrapper {
    overflow: hidden;
  }

  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
    position: relative !important;
  }

  .sidebar.is-open>.sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

.container a {
  text-decoration: none;
}

.dropdown-btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.dropdown-btn p {
  margin-bottom: 0;
}

.content:where(.is-open)>.container {
  width: 950px;
}

#response-success:not(:empty) {
  background-color: rgb(125, 235, 125);
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
}

#response-error:not(:empty) {
  background-color: rgb(236, 142, 142);
  font-weight: bold;
  padding: 10px 0;
  margin: auto;
  text-align: center;
}

.voucher-ids {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.voucher-ids:hover,
.voucher-ids:active {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.702);
}