.trackingOrderWrapper {
  padding: 16px 18px;
  padding-right: 10px;
  /* font-family: "Quicksand"; */
  width: 100%;
  margin-top: var(--pageMarginTop);
}

.trackingOrderContent {
  display: flex;
  padding-bottom: 30px;
}

.trackingOrderLeft {
  width: 60%;
  padding: 0px 100px;
}

.trackingOrderTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 35px;
}

.trackingOrderHeaderContainer {
  display: flex;
  gap: 20px;
}

.trackingOrderHeader {
  width: 50%;
}

.trackingOrderAbout1 {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 0;
}

.trackingOrderAbout2 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #707070;
}

.trackingOrderImage {
  width: 400px;
  height: 300px;
}

.trackingOrderTrack {
  width: 40%;
  padding-top: 85px;
}

.trackingOrderDate,
.trackingOrderNames {
  font-style: normal;
  font-weight: 650;
  font-size: 25px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 20px;
}

.trackingOrderNames {
  margin-bottom: 0;
  font-size: 24px;
}

.orderedTodayContainer {
  display: flex;
  align-items: center;
}

.OrderTodayCheckbox {
  width: 24px;
  height: 24px;
  border: 1px solid #707070;
  border-radius: 5px;
  margin-right: 28px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  position: relative;
}
.OrderTodayCheckbox::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #1b325e;
  opacity: 0;
  font-size: 1.2rem;
}
.OrderTodayCheckbox:checked::before {
  opacity: 1;
}

.trackingOrderline {
  margin-left: 12px;
  width: 1px;
  height: 100px;
  background-image: linear-gradient(to bottom, #1b325e, #d3d0d0);
}

.trackingOrderNotCompleted {
  margin-left: 12px;
  width: 1px;
  height: 70px;
  background-image: linear-gradient(to bottom, #d3d0d0, #d3d0d0);
}

.notDone {
  color: #d3d0d0;
}

@media screen and (max-width: 500px) {
  .trackingOrderWrapper {
    padding-right: 0;
  }

  .trackingOrderContent {
    flex-direction: column;
  }

  .trackingOrderLeft {
    width: 100%;
    padding: 0px;
  }

  .trackingOrderTitle {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .trackingOrderAbout1 {
    font-size: 20px;
    line-height: 24px;
  }

  .trackingOrderAbout2 {
    font-size: 10px;
    line-height: 20px;
  }

  .trackingOrderImageContainer {
    margin-top: -27px;
  }

  .trackingOrderImage {
    width: 170px;
    height: 130px;
  }

  .trackingOrderTrack {
    width: 100%;
    padding-top: 0px;
    margin-top: -35px;
  }

  .trackingOrderDate,
  .trackingOrderNames {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }

  .trackingOrderline {
    height: 70px;
  }

  .trackingOrderNotCompleted {
    height: 70px;
  }

  .OrderTodayCheckbox {
    margin-right: 15px;
  }
}

@media screen and (max-width: 370px) {
  .trackingOrderImageContainer {
    margin-top: -13px;
  }

  .trackingOrderImage {
    width: 130px;
    height: 100px;
  }
}
