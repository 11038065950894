.banner-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.banner {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-evenly;
}
.bannerimg {
  width: 100%;
  object-fit: fill;
  margin-bottom: 4px;
  cursor: pointer;
}
.mobilescreen {
  display: flex;
  margin-top: 10px;
}
.mobilescreen img {
  max-height: 58px;
}
.desktop {
  display: none;
}
@media screen and (min-width: 708px) {
  .banner-component {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    width: 100%;
    height: 100%;
  }
  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 370px;
    height: 100%;
  }
  .bannerimg {
    width: 24.5% !important;
    max-height: 250px;
    object-fit: fill;
    border-radius: 10px;
    cursor: pointer;
  }
  .mobilescreen {
    display: none;
  }
  .desktop {
    display: flex;
    margin-top: 8px;
  }
}

@media screen and (max-width: 500px) {
  .mobilescreen img {
    /* height: 120px; */
    max-height: 100%;
  }
}
