.pickup_Search_Bar_Container{
  margin: 0.5rem 0 0.75rem;
  padding: 0.5rem 1rem;
  background: #1B325E;
}

.pickup_Search_Bar{
  position: relative;
  width: 100%;
}

.store_Search_Bar{
  padding: 0.5rem 2.625rem;
}

.store_Search_Bar::placeholder{
  color: #6D747A;
}

.pickup_Search_Bar img{
  position: absolute;
  top: 8px;
  left: 12px;
}

.store_Page_Separtor{
  margin: 0 0 0.75rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.hr_Line{
  /* position: absolute; */
  background: #6D747A;
  height: 1px;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.store_Page_Separtor p{
  /* background-color: #eeeeec; */
  position: relative;
  margin: 0;
  color: #6D747A;
  letter-spacing: -0.015em;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0 0.25rem;
}

.near_Store_Button{
  padding: 0 1rem;
  margin: 0 0 24.75rem;
}

.near_Store_Button button{
  background: #DDE0E7;
}

.near_Store_Button button{
  max-width: 95%;
}

.near_Store_Button button p{
  color: #1B325E !important;
}

.store_Search_List{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.25rem 0 2rem;
}

.store_Seach_Option{
  background: #FFFFFF;
}

.location_Alert{
  padding: 0.875rem 1rem;
  background: #FFF0BF;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.location_Alert p{
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.location_Refresh{
  margin: 10rem 0 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
}

.location_Refresh > p{
  margin: 0;
  color: #08090A;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.location_Refresh_Button{
  max-width: 125px;
  width: 100%;
}

.store_Pick_Loader{
  padding: 1.5rem 1rem;
  background: #FFFFFF;
  border: 1px solid #DDE0E7;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 0.25rem;
}

.store_Pick_Loader > div{
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .pickup_Search_Bar_Container{
    padding: 0.375rem 0.75rem;
    background: #FFFFFF;
    border-radius: 4px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
  }

  .store_Page_Separtor, .near_Store_Button{
    padding: 0;
  }

  .store_Page_Separtor p{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .hr_Line{
    width: 100%;
  }

  .location_Refresh{
    padding: 0;
    margin: 7.5rem 0 18rem;
  }
}

@media screen and (min-width: 1200px) {
  .location_Refresh{
    margin: 3.75rem 0 8.25rem;
  }
}
