.address{
  background-color: #FFFFFF;
}

.address_Box{
  padding: 1rem 0.75rem 0.125rem;
  background-color: #FFFFFF;
  position: relative;
}

/*
.address_Box_Wrapper_Section{
  display: flex;
  padding-right: 10px;
} 
*/

.address_Box_Wrapper{
  padding: 0 0 1rem;
  border-bottom: 1px solid #EBEDF0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 0.25rem;
}

.address_Box_Wrapper p{
  margin: 0;
  color: #08090A;
}

.address_Box_Wrapper p:nth-of-type(2), .address_Box_Wrapper p:nth-of-type(3){
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.address_Default_Button{
  max-width: 145px;
  width: 100%;
  position: absolute;
  top: -0.6rem;
  right: 1.5rem;
}

.address_Default_Button.disable{
  opacity: 0.6;
  pointer-events: none;
}

.address_Box_Name{
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
}

.address_Box_Footer{
  margin: 0 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.address_Box_Footer img{
  max-width: 18px;
  max-height: 18px;
  height: 100%;
  width: 100%;
}

.address_Box_Footer p{
  margin: 0;
  color: #3D5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.address_Footer_Delete, .address_Footer_Edit{
  width: 80%;
  display: flex;
  /* align-items: center; */
  justify-content: end;
  gap: 0.25rem;
  margin: 0.125rem 0 0;
  padding: 0.75rem 0 0.63rem;
  cursor: pointer;
  text-decoration: none;
}

.address_Footer_Delete{
  border-right: 1px solid #DDE0E7;
  filter: grayscale(1);
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

.address_Footer_Edit{
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

@media screen and (min-width: 768px) {
  .address_Box{
    padding: 1rem 0.75rem 0.25rem;
  }

  .address_Box_Name{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .address_Box_Wrapper p:nth-of-type(2){
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .address_Footer_Delete, .address_Footer_Edit{
    gap: 0.375rem;
  }

  .address_Box_Footer p{
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}