.cart_Price_details{
  padding: 1rem 0.75rem 0.75rem;
  background-color: #FFFFFF;
  margin: 0 0 1rem;
}

.cart_Details_Header{
  padding: 0 0.25rem 0.75rem;
  border-bottom: 1px solid #EBEDF0;
  /* margin: 0 0.25rem; */
}

.cart_Details_Header p{
  margin: 0;
  letter-spacing: -0.01em;
  color: #08090A;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.cart_Details_Body{
  padding: 0.75rem 0.25rem;
  /* margin: 0 0.25rem; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px solid #EBEDF0;
}

.cart_Details_Price , .cart_Details_Discount, .cart_Details_Delivery{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart_Details_Price p, .cart_Details_Discount p, .cart_Details_Delivery p {
  color: #08090A;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
}

.cart_Details_Discount p:last-child{
  color: #3F8F00;
}

.cart_Details_Footer{
  padding: 0.75rem 0.25rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 0.25rem; */
}

.cart_Details_Footer p{
  letter-spacing: -0.01em;
  color: #08090A;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
}

.cart_Details_Footer p:last-child{
  color: #3F8F00;
}

.cart_Details_Hide{
  margin: 0.75rem 0 0;
  border-top: 0.063rem solid #EBEDF0;
  text-align: center;
  padding: 0.75rem 0.25rem 0;
}

.cart_Details_Hide p{
  margin: 0;
  color: #3D5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .side_Section .cart_Price_details{
    border-radius: 0.25rem;
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {
  .cart_Details_Header p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

    .cart_Details_Price p,
    .cart_Details_Discount p,
    .cart_Details_Delivery p,
    .cart_Details_Footer p {
      font-size: 1rem;
      line-height: 1.5rem;
    } 
}