.header_Sort_Container {
  background-color: white;
  margin: 0.375rem 0 0;
}

.Product_Category_Container_res {
  display: grid;
  grid-template-rows: 1fr;
  gap: 0.75rem;
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-image {
  opacity: 0.8;
  animation: blink 1s infinite alternate; /* Blinking animation */
}

@keyframes blink {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.bottom_Sheet_Backdrop {
  background: transparent;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}
.drawerSection {
  /* Styles for the static filters */
  position: sticky;
  top: 90px;
  left: 0;
  width: 250px; /* Adjust as needed */
}

.scrollable-product {
  /* Styles for the scrollable product part */
  flex-grow: 1;
  padding-left: 10px; /* Optional: Add padding to align with static filters */
}
.bottom_Sheet_Backdrop.active {
  position: fixed;
  pointer-events: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(8, 9, 10, 0.6);
  z-index: 100;
}

.bottom_Sheet {
  position: absolute;
  display: none;
  bottom: -500vh;
  left: 0;
  width: 100%;
  z-index: 200;
  background: #fff;
  height: auto;
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.bottom_Sheet.active {
  display: block;
  bottom: 0;
}

.bottom_Sheet_Header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ced4da;
}

.bottom_Sheet_Heading {
  letter-spacing: -0.01em;
  color: #000000;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0 !important;
}

.bottom_Sheet_Close_Btn {
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
}

.bottom_Sheet_Body {
  padding: 1rem;
}

.price_Sort_Labels {
  color: #6d747a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.price_Sort_Labels {
  margin: 0 0 0.7rem;
}

.price_Sort_Labels input {
  width: fit-content !important;
}

.header_Filter_Button {
  text-decoration: none;
}

.filter_Container {
  display: flex;
  align-items: center;
  justify-self: center;
  height: 60vh;
}

.filter_Left {
  max-width: 140px;
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.03);
  height: 100%;
}

.filter_Right {
  background: #ffffff;
  width: 100%;
  height: 100%;
  padding: 0 0.25rem;
}

.filter_Left_Item {
  padding: 0.875rem 1rem;
  background: #ebedf0;
  margin-bottom: 1px;
  cursor: pointer;
}

.filter_Left_Item:has(:hover, :focus) {
  font-weight: bolder;
  letter-spacing: 0.5px;
  background-color: hsl(216, 14%, 88%);
  transition: all ease-in 50ms;
}

.filter_Left_Item.filter_Heading_Selected {
  background: #ffffff;
}

.filter_Left_Item p {
  color: #6d747a;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.filter_Left_Item.filter_Heading_Selected p {
  color: #08090a;
}

.filter_Right_Item {
  padding: 0.875rem 0.75rem 0.875rem 0.25rem;
  background: #ffffff;
  border-bottom: 1px solid #ced4da;
}

.filter_Right_Item:last-child {
  border: none;
}

.offers_Toggle_Header {
  max-height: 48px;
  width: 100%;
  margin: 0.5rem 0 0;
}

.offers_Toggle_Container {
  border: 1px solid #1b325e;
  background: #ebedf0;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
}

.offers_Toggle_Container p {
  padding: 0.75rem 2.25rem;
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: all 0.2s ease-in-out;
  color: #939ca3;
  cursor: pointer;
  width: 50%;
  text-align: center;
}

.offers_Toggle_Container p.offer_Selected {
  background: #1b325e;
  border-radius: 0 0.25rem 0.25rem 0;
  color: #fff;
}

.offers_Toggle_Container p:nth-child(2).offer_Selected {
  border-radius: 0.25rem 0 0 0.25rem;
}

.side_Section_Body .accordion .accordion-item .accordion-collapse {
  background: transparent;
}

.new_Filter_Container {
  transform: translateX(150%);
  transition: all 0.4s ease-in-out;
}

.new_Filter_Container.new_Filter_Show {
  transform: translateX(0);
  height: 100%;
  width: 100%;
}

.main_Content_Show {
  scroll-margin-top: 4rem;
  display: block;
  transition: all 0.5s ease-in-out;
}

.main_Content_Hide {
  display: none;
}

@media screen and (min-width: 568px) {
  .head2xl,
  .aside-items span {
    font-size: 0.8rem;
  }
  .price-range span {
    font-size: 0.5rem;
    margin-bottom: 8px;
  }
  .price-range + div > button {
    font-size: 0.6rem;
    padding: 5px 0;
  }
  .aside-items:first-child span {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 768px) {
  .Product_Category_Container_res {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 1.5rem 0.5rem;
    justify-items: flex-end;
  }

  .side_Section_Body .accordion .accordion-item:not(:last-of-type) {
    border-bottom: 1px solid #ebedf0;
  }

  .side_Section_Body .accordion .accordion-item {
    border: none;
  }

  .side_Section_Body .accordion .accordion-item .accordion-header .accordion-button {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    color: #08090a;
    letter-spacing: -0.01em;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding: 0.75rem 0;
  }

  .side_Section_Body .accordion .accordion-item .accordion-header .accordion-button::after {
    background-image: url("../../assets/vector/add_outline_grey.svg");
  }

  .side_Section_Body .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
    background-image: url("../../assets/vector/remove_line_grey.svg");
    transform: none;
  }

  .side_Section_Body .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 0.75rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .filter_Sort_Header {
    margin: 1rem 0 0;
    display: flex;
    justify-content: space-between;
  }

  .filter_Section_Header {
    max-width: 600px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .sort_Section_Header {
    max-width: fit-content;
    min-width: fit-content;
    height: auto;
  }

  .offers_Toggle_Header {
    max-height: 32px;
    min-width: 256px;
    max-width: 256px;
    width: 100%;
    margin: 0;
  }

  .offers_Sort_Header {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .offers_Toggle_Container {
    border: 1px solid #1b325e;
    background: #ebedf0;
    border-radius: 0.25rem;
    display: flex;
    width: 100%;
    max-width: 256px;
  }

  .offers_Toggle_Container p {
    padding: 0.375rem 1rem;
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    transition: all 0.2s ease-in-out;
    color: #939ca3;
    cursor: pointer;
  }

  .offers_Toggle_Container p.offer_Selected {
    background: #1b325e;
    border-radius: 0 0.25rem 0.25rem 0;
    color: #fff;
  }

  .sort_Section_Header .dropdown .dropdown-toggle {
    background: transparent !important;
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
  }

  .sort_Section_Header .dropdown .dropdown-toggle::after {
    border: none;
    display: none;
  }

  .sort_Section_Header .dropdown .dropdown-toggle:hover {
    background: transparent;
    border: 1px solid #ced4da;
  }

  .sort_Section_Header .show > .btn.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    background: transparent;
    border: 1px solid #ced4da;
  }
  .sort_Section_Header .show > .dropdown-toggle.btn.btn-primary:focus,
  .sort_Section_Header .show > .dropdown-toggle.btn.btn-primary:focus-visible {
    box-shadow: none;
    background: transparent;
    border: 1px solid #ced4da;
  }

  .sort_Container {
    display: flex;
    gap: 0.75rem;
    justify-content: space-between;
    align-items: center;
  }

  .sort_Container span,
  .sort_Container p {
    color: #6d747a;
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .sort_Container p {
    color: #08090a;
  }

  .sort_Container img {
    max-width: 20px;
    max-height: 20px;
    width: 100%;
    height: 100%;
  }

  .sort_Section_Header .dropdown-menu {
    width: 100%;
  }

  .sort_Section_Header .dropdown .dropdown-menu .dropdown-iten:active {
    background: #e9ecef;
  }

  .price-range span {
    font-size: 0.8rem;
    margin-bottom: 8px;
  }

  .price-range + div > button {
    font-size: 0.7rem;
  }
  .tag-discount {
    margin-right: 4px;
  }
}

@media screen and (min-width: 850px) {
  .Product_Category_Container_res {
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .Product_Category_Container_res {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 1rem;
  }

  .all-offer-comp {
    flex-wrap: nowrap !important;
  }
}

.loader {
  margin: auto;
  border: 4px solid hsl(219, 90%, 40%);
  border-radius: 50%;
  border-top: 3px solid hsl(219, 90%, 70%);
  width: 25px;
  height: 25px;
  animation: spinner 2s ease-in infinite;
}

@media screen and (max-width: 700px) {
  .Product_Category_Container_res {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media screen and (max-width: 580px) {
  .Product_Category_Container_res {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.desk_Page_Wrapper_res {
  display: flex;
}
.pageWrapper {
  margin-top: var(--pageMarginTop);
  width: 100%;
}
.all-categories-banner img {
  width: 100%;
  cursor: pointer;
}

.all-categories-devices {
  display: flex;
  justify-content: space-evenly;
  padding: 0px 2rem;
  flex-wrap: wrap;
  width: 80%;
}
.all-cat-items {
  margin: 0 10px;
  text-align: center;
  /* width: 100%; */
  min-width: 120px;
  /* overflow: hidden; */
}
.all-cat-items img {
  width: 120px;
  height: 120px;
}
.all-cat-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#all-mid-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  /* font-family: "Quicksand"; */
  /* margin-bottom: 5px;
  margin-top: 5px; */
}

.all-cat-logos {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.logo-con-1 {
  /* width: 40%; */
  height: fit-content;
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}
.logo-con-2 {
  /* width: 40%; */
  height: fit-content;
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}
.logo-con-1 img {
  width: 20%;
  height: 100%;
  min-width: 80px;
  margin: 10px;
}
.logo-con-2 img {
  width: 20%;
  height: 100%;
  margin-right: 7px;
  min-width: 80px;
}
.prod-brand-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.brand-logo-cont {
  display: flex;
  width: 80%;
  gap: 15px;
}
.brand-logo-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:where(.logo-img, .brand-logo-img, .all-cat-items):where(:hover, :focus-visible) {
  cursor: pointer;
  scale: 1.1;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.brand-logo-img[data-selected="selected"] {
  scale: 1.5;
}
.all-cat-items[data-selected="selected"],
.logo-img[data-selected="selected"] {
  scale: 1.15;
}
.brand-logo-img img {
  width: 60%;
  height: 60%;
  max-width: 70px;
  max-height: 70px;
}

.all-offer-comp {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
  flex-wrap: wrap;
}
.all-offer-items {
  display: flex;
  flex-direction: column;
  border: 1px solid #ffcc0a;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0.4rem;
  width: 15%;
  height: 35px;
  border-radius: 10px;
}
.all-offer-items:where(:hover, :focus-visible, [data-priceselected="priceselected"]) {
  cursor: pointer;
  box-shadow: #f4de9d 0px 10px 30px -12px inset, #ebd789 0px 18px 18px -10px inset;
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.all-offer-items span {
  font-size: 21px;
  font-weight: 500;
  /* font-style: italic; */
}
.all-cat-banners {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.all-cat-banner-item1 {
  width: 39%;
  height: 100%;
  margin-right: 10px;
}
.all-cat-banner-item2 {
  width: 70%;
  height: 100%;
}
.all-cat-banner-item1 img {
  width: 100%;
  height: 100%;
}
.all-cat-banner-item2 img {
  width: 94%;
  height: 80%;
  min-height: 272px;
}
.Product_Category_Container_res {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 2.5rem;
}
.right-wrapper {
  width: 100%;
  height: 100%;
}

.SpotLight_res {
  display: flex;
  justify-content: space-between;
  padding: 2px 2rem;
  align-items: center;
  background: #f5f5f5;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 10px;
}
.pagination-con {
  display: flex;
  justify-content: center;
  align-items: center;
}
.desk_Page_Wrapper_res {
  padding: 10px 15px 30px 10px;
  width: 100%;
  position: relative;
}
.head2xl {
  text-transform: capitalize;
  font-size: 1rem;
}
.aside_res {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 1.5rem 1.2rem;
  /* font-family: "Quicksand"; */
  font-weight: 700;
  font-size: 20px;
  background: #f8f8f8;
  border-radius: 10px;
}
.aside-items hr {
  width: 80%;
  color: black;
}
.aside_container_res {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.aside-items {
  width: 100%;
}
.Accordian_res {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 0px; */
  box-shadow: none;
  border: none;
  outline: none;
}
.accordian-itm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
}
.Accor-dot {
  width: 15px;
  height: 14px;
  border-radius: 100%;
  background-color: transparent;
  border: 1.5px solid rgb(69, 68, 68);
  margin-right: 0.5rem;
}
.accordian-section1 {
  display: flex;
  flex-direction: column;
  margin: 0rem 0rem;
  padding: 0 0.5rem;
  color: #707070;
  font-size: 14px;
}
#color-orn {
  background-color: #ffa500;
}
.acoor-sec-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}
.acoor-sec-item:last-child {
  margin-bottom: 0;
}
.aside-price-range {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.price-range {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.acit {
  margin-right: 15px;
}

.order_Page_Right_res {
  width: 100%;
  padding: 0px 22px 0px 35px;
  height: 100%;
}

.empty_order_sec {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 80px 0;
  margin: 0 0 1rem;
}

.empty_order_text {
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1000px) {
  .all-categories-devices {
    display: flex;
    justify-content: center;
    padding: 0px;
  }
  .all-cat-banners {
    display: none;
  }
  .logo-con-1,
  .logo-con-2 {
    flex-wrap: wrap;
    width: 30%;
    gap: 5px;
  }
  .logo-con-1 img {
    min-width: 50px;
  }
  .logo-con-2 img {
    min-width: 55.8px;
  }
  .brand-logo-img img {
    width: 100%;
    height: 100%;
    max-width: 33px;
  }
  #all-mid-heading {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  .all-offer-comp {
    padding: 0.4rem;
    flex-wrap: wrap;
  }
  .all-offer-items {
    margin: 0.2rem;
    width: 15%;
    height: 25px;
  }
  .all-offer-comp.secondRow {
    width: 100%;
  }
  .all-offer-items.secondItem {
    width: 30%;
  }
  .all-offer-items span {
    font-size: 0.75rem;
  }
  .aside_res {
    width: 100%;
  }
  .Product_Category_Container_res {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .order_Page_Right_res {
    padding: 0px 0px 23px 0px;
  }
  .head2xl {
    font-size: 1.3rem;
  }
  .accorItemName {
    font-size: 1.1rem;
  }
  .main_Content_Show {
    scroll-margin-top: 8rem;
  }
}

.backBtnDrawer {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  color: #262626;
}

.doneBtnDrawer {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: #262626;
}

.css-1t2f62z-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}
.css-kr5692::before {
  display: none;
}

[data-filterselected="selected"] {
  background-color: var(--primaryYellow);
}

/* Highlight the header of the selected filter */
.Accordian_res:has([data-filterselected="selected"]) {
  color: var(--primaryYellow);
}

.MuiAccordionSummary-content {
  text-transform: capitalize;
}

.acoor-sec-item:where(:hover, :focus-visible) {
  cursor: pointer;
}

.order_Page_Right_res {
  display: flex;
  min-height: 22vh;
}

.empty_order_sec_res {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SpotLight_res-items {
  cursor: pointer;
}

/* .offer-banners img:hover,
.offer-banners img:focus-visible {
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  filter: brightness(1.11);
  scale: 1.02;
  cursor: pointer;
} */

@media screen and (min-width: 569px) and (max-width: 630px) {
  .price-range + div {
    right: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-left: 1rem;
  }
}

@media screen and (min-width: 569px) and (max-width: 1200px) {
  .accordian-itm {
    width: 114% !important;
  }
}

@media screen and (min-width: 1600px) {
  .Product_Category_Container_res {
    grid-template-columns: repeat(5, 1fr);
    row-gap: 1rem;
  }
}
