.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.carousel-indicators {
  margin-bottom: 0px !important;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}

.carousel-indicators [data-bs-target] {
  /* max-width: 12px !important;
  max-height: 12px !important; */
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  background-color: #dde0e7 !important;
  opacity: 1 !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.carousel-indicators .active {
  background-color: #1b325e !important;
}

.carousel_image {
  cursor: pointer;
  aspect-ratio: 16 / 7;
}

.carousel-caption {
  left: 50% !important;
  top: 50% !important;
  right: 0 !important;
  width: 50% !important;
}

.img_caption {
  position: relative;
  width: 100%;
  display: none;
  /* visibility: visible; */
  background-color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: all 0.5s ease-in-out;
}

.img_caption::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.active.carousel-item:hover .img_caption {
  display: flex;
  /* visibility: visible; */
}

.img_caption_text {
  margin: 0;
  overflow: hidden;
  font-size: 0.625rem;
  line-height: 1rem;
}

.img_caption_link {
  color: blue;
  cursor: pointer;
  font-size: 0.625rem;
  line-height: 1rem;
}
.carousel_image,
.carousel_video {
  width: 100%;
  object-fit: cover;
  min-height: 190px;
  max-height: 450px;
}

@media screen and (min-width: 768px) {
  .img_caption {
    width: 70%;
  }

  .img_caption_text,
  .img_caption_link {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .video-container {
    position: relative;
    overflow: hidden;
    height: 450px;
  }

  .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* .carousel-inner{
    max-height: 400px;
  } */

  .carousel_image {
    max-height: 450px;
    object-fit: cover;
  }
}

@media screen and (max-width: 500px) {
  .video-container {
    height: 190px;
  }
}
@media screen and (max-width: 600px) {
  .carousel-indicators {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1024px) {
  .carousel-control-prev,
  .carousel-control-next {
    display: flex !important;
    max-width: 48px !important;
    max-height: 96px !important;
    width: 100% !important;
    height: 100% !important;
    background: transparent !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    top: 50% !important;
    transition: background 0.5s ease-in-out;
    transform: translateY(-50%);
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    max-width: 48px !important;
    max-height: 48px !important;
    width: 100% !important;
    height: 100% !important;
  }
  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    background: white !important;
  }

  .carousel-control-prev-icon {
    background-image: url("../../assets/vector/arrow_left_black.svg") !important;
  }
  .carousel-control-next-icon {
    background-image: url("../../assets/vector/arrow_right_black.svg") !important;
  }
  .carousel-indicators [data-bs-target] {
    width: 12px !important;
    height: 12px !important;
  }
  .img_caption_text,
  .img_caption_link {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
