.nav-item {
  color: black;
  cursor: pointer;
}

.nav-link.active {
  color: #6ada7d !important;
  font-weight: bold !important;
}

.nav-item:hover {
  color: rgb(83, 83, 201);
  font-weight: bold;
  background-color: white;
}
