.headerContainer {
  background: #1b325e;
  width: 100%;
  height: 4rem;
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 8px 0;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 4px;
}

.logo {
  max-width: 128px;
  max-height: 50px;
  cursor: pointer;
}

.logo img {
  width: 100%;
  height: 100%;
}

.logo_desk {
  display: none;
}

.logo_desk_1200 {
  display: none;
}

.logo_tab {
  display: none;
}

.alternate-way {
  font-size: 14px;
  line-height: 1rem;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: white;
  cursor: pointer;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .headerContainer {
    height: 4.5rem;
    padding: 16px 60px;
  }

  .logo_mob {
    display: none;
  }

  .logo_desk {
    display: block;
  }

  .logo_tab {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .headerContainer {
    padding: 16px 175px;
  }

  .logo {
    max-width: 156px;
    max-height: 44px;
  }

  .alternate-way {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .logo_tab {
    display: none;
  }

  .logo_desk_1200 {
    display: block;
    width: 140px;
  }
}
