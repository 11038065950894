/* @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
/*kannada-font*/
/* @font-face {
    font-family: "Noto Sans Kannada";
    src: url(/src/assets/fonts/Noto_Serif_Kannada/NotoSerifKannada-VariableFont_wght.ttf);
    unicode-range: U+0964-0965, U+0C82-0CF2, U+200C-200D, U+20B9, U+25CC;
} */
@font-face {
  font-family: "Amazon Ember";
  font-weight: 200;
  src: url(https://m.media-amazon.com/images/S/sash/Dv1WQ5DdeMS5qP7.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/e0LnMbFWJC-TMQz.woff) format("woff");
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 200;
  font-style: italic;
  src: url(https://m.media-amazon.com/images/S/sash/ozb5-CLHQWI6Soc.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/twzZHebXjCHBb6v.woff) format("woff");
}

@font-face {
  font-family: "Amazon Ember";
  src: url(https://m.media-amazon.com/images/S/sash/pDxWAF1pBB0dzGB.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/kfKKBuoqcD$AUKL.woff) format("woff");
}

@font-face {
  font-family: "Amazon Ember";
  font-style: italic;
  src: url(https://m.media-amazon.com/images/S/sash/WOnTLzkiaEccV7F.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/JJsp0ZvgpfwzJM6.woff) format("woff");
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 500;
  src: url(https://m.media-amazon.com/images/S/sash/VjTR4RqBzY0mUYx.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/XIvhNCZAsrT80Wz.woff) format("woff");
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 500;
  font-style: italic;
  src: url(https://m.media-amazon.com/images/S/sash/KwhNPG8Jz-Vz2X7.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/YAEPfuhs1l-argd.woff) format("woff");
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 700;
  src: url(https://m.media-amazon.com/images/S/sash/KFPk-9IF4FqAqY-.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/mzVbGSgvdBfRLX9.woff) format("woff");
}

@font-face {
  font-family: "Amazon Ember";
  font-style: italic;
  font-weight: 700;
  src: url(https://m.media-amazon.com/images/S/sash/2SazJx$EeTHfhMN.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/CjbL$jCCegyfqZ7.woff) format("woff");
}

body {
  margin: 0;
  font-family: "Amazon Ember", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: "Amazon Ember", Arial, sans-serif !important;
}
