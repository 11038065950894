.coupon-container {
  display: flex;
  flex-wrap: wrap;
}
.statusDiv {
  /* border: 1px solid #1b325e; */
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 4px 0px;
}
.statusDivclaimed {
  border: 1px solid #1b325e;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* padding: 4px 0px; */
  width: 96%;
}

/* Keyframes for the blinking effect */
@keyframes blinkBorder {
  0% {
    border-color: #1b325e;
  }
  50% {
    border-color: #4a90e2; /* Light blue color */
  }
  100% {
    border-color: #1b325e;
  }
}

/* Apply the blinking border for the active status */
.statusDivactive {
  width: 96%;
  border-radius: 10px;
  border: 1px solid #87b1ff; /* Default border color */
  animation: glow 2.5s infinite; /* Blinking effect */
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0px #1b325e, 0 0 10px #1b325e, 0 0 0px #4a90e2;
  }
  50% {
    box-shadow: 0 0 10px #1b325e, 0 0 20px #4a90e2, 0 0 0px #73b4ff;
  }
  100% {
    box-shadow: 0 0 0px #1b325e, 0 0 10px #1b325e, 0 0 0px #4a90e2;
  }
}

@media screen and (max-width: 500px) {
  .coupon-container {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    gap: 10px;
  }
}

.couponeCard {
  height: 200px;
  background-image: url("../assets/png/bg.png");
  background-size: 390px 200px;
  width: 390px;
  padding: 30px 10px;
  border: 1px solid #1b325e;
}

.usedCouponCard {
  background-image: url("../assets/png/bg-grey.png");
  background-size: 390px 200px;
  width: 390px;
  padding: 30px 10px;
  border: 1px solid #1b325e;
}

.coupnImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.coupnImage img {
  width: 100px;
}

.coupnImage .d-flex {
  width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.coupnImage .d-flex img {
  width: 65%;
}
.startChildDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

.coupnImage .d-flex .couponText {
  font-size: 10px;
  margin: 10px 0px;
  align-items: center;
  display: flex;
  text-align: center;
}

.coupnImage .d-flex .couponText img {
  height: 12px;
  margin-right: 5px;
  margin-left: 5px;
}

.coupnImage .middleLine {
  width: 1.5px;
  background-color: #1b325e;
  height: 130px;
}

.coupnImage .cashBack {
  width: 55%;
  text-align: center;
}

.coupnImage .cashBack img {
  width: 150px;
  height: 30px;
}

.coupnImage .cashBack p {
  font-size: 24px;
  font-weight: bold;
  color: #1b325e;
}
.startChildDiv {
  margin: 5px 0px;
}
@media screen and (max-width: 500px) {
  .couponeCard {
    height: 150px;
    background-image: url("../assets/png/bg.png");
    background-size: 100% 150px;
    width: 48%;
    align-content: center;
    padding: 6px 10px;
  }

  .usedCouponCard {
    height: 150px;
    background-image: url("../assets/png/bg-grey.png");
    background-size: 100% 150px;
    width: 48%;
    padding: 15px 10px;
  }

  .coupnImage {
    /* flex-direction: column; */
    gap: 7px;
  }
  .startDiv {
    width: 100%;
  }
  .coupnImage .d-flex .couponText {
    margin: 0px;
  }
  .coupnImage .d-flex {
    width: 55%;
    line-height: 14px;
  }
  .coupnImage .middleLine {
    height: 90px;
  }
  .coupnImage .cashBack p {
    font-size: 12px;
  }

  .coupnImage .cashBack {
    width: 50%;
  }
  .coupnImage .cashBack img {
    object-fit: contain;
    width: 100%;
  }
}
