.findStoreContainer {
  width: 100%;
  padding: 0px 100px;
  /* font-family: "Quicksand"; */
  font-style: normal;
  margin-top: var(--pageMarginTop);
  margin-bottom: 25px;
}

.findStoreTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

/* Section Ends  */

.findStoreMapSearchContainer {
  width: 410px;
  margin-bottom: 40px;
}

.findStoreMapTitle {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
  margin-top: 10px;
}

.findStoreMapTitle span {
  text-transform: capitalize;
}

.findStoreMapInputTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #707070;
  /* margin-top: 20px; */
}

.findStoreMapSearch {
  display: flex;
  border: 1px solid #bebebe;
  padding: 5px 10px;
  border-radius: 6px;
}

.findStoreMapSearchIcon {
  margin-right: 10px;
  font-weight: 300;
  font-size: 1rem;
  color: #7f7f7f;
}

.findStoreMapSearchbar {
  outline: none;
  border: none;
  width: 390px;
}
.findStoreMapSearchbar::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7f7f7f;
}

/* Section Ends  */

.stickyHeader {
  position: sticky;
  top: 0;
  background-color: #1b325e;
  z-index: 1; /* Ensure the sticky header appears above the scrollable content */
  height: 50px;
  align-content: center;
  padding: 0px 10px;
}

.stickyHeaderContent {
  display: flex;
  align-items: center;
  gap: 7px;
  /* margin: 8px 0px; */
  justify-content: space-between;
  color: white;
}

.scrollableContent {
  overflow-y: auto; /* Enable vertical scrolling for the content */
  max-height: calc(100vh - 100px); /* Adjust max-height as needed */
}

.findStoreContactHeader {
  /* margin-top: 10px; */
  display: flex;
  flex-direction: column;
}

.findStoreContactBurgerIcon {
  height: 32px;
  width: 32px;
}

.findStoreContactHeaderTitle,
.findStoreContactPersonName {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.findStoreContactPersonAddress {
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  color: #707070;
  width: 500px;
}
.horizontal-row {
  height: 1px;
  background-color: #ccc;
  margin: 5px 0;
}

.findStoreContactPersonIcon {
  height: 20px;
  width: 20px;
  margin-top: 2px;
}
.findStoreContactPersonNumber {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #0066c0;
}

.getNearBystore {
  width: fit-content;
  padding: 5px;
}

.searchButton {
  background-color: #1b325e;
  color: white;
  height: 35px;
  padding: 5px;
  border-radius: 5px;
  border: none;
}

.findStoreContactPersonPhone {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .findStoreContainer {
    padding: 0px 20px;
  }

  .findStoreMapSearchContainer {
    width: 200px !important;
  }
  .findStoreTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }

  /* Section Ends  */

  .findStoreMapSearch {
    padding: 1px 6px;
  }
  .findStoreMapSearchIcon {
    margin-right: 5px;
    font-weight: 300;
    font-size: 0.4rem;
  }
  .findStoreMapSearchContainer {
    width: 300px;
  }
  .findStoreMapSearchbar {
    width: 290px;
  }
  .findStoreMapSearchbar::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7f7f7f;
  }

  /* Section Ends  */
  .findStoreContactHeaderTitle,
  .findStoreContactPersonName {
    font-size: 16px;
    line-height: 30px;
  }

  .findStoreContactPersonAddress {
    font-weight: 600;
    font-size: 16px;
    width: 330px;
  }

  .findStoreContactPersonIcon {
    height: 18px;
    width: 18px;
    margin-top: 0px;
  }
  .findStoreContactPersonNumber {
    font-size: 15px;
    line-height: 20px;
  }
  .findStoreMapTitle {
    font-size: 20px;
  }

  .findStoreContactPersonPhone {
    display: flex;
    gap: 10px;
    /* margin-top: 10px; */
  }
}

.findStorecontainer2 {
  display: flex;
  max-height: 550px;
  min-height: 500px;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  padding: 20px;
  width: 45%;
}

.scrollable {
  flex: 1;
  overflow-y: auto;
  border: 1px solid #ccc;
  max-height: 100%; /* Adjust height as needed */
}

.scrollable > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
} */

/* li:last-child {
  border-bottom: none;
} */
