.order_Details_Header {
  background: #ffffff;
  padding: 1rem;
  margin: 0.75rem 0;
}

.order_Details_Header p {
  margin: 0;
}

.header_Order_Status {
  letter-spacing: -0.01em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 0 0.375rem;
}

.header_Order_Number {
  letter-spacing: -0.015em;
  color: #6d747a;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.order_Track_Container {
  padding: 1rem;
  background: #ffffff;
}

.order_Track_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.order_Track_Step {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  position: relative;
}

.order_Track_Step::after,
.order_Track_Step::before {
  border-left: 2px dashed #cccccc;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 8px;
}

.order_Track_Step::before {
  top: -45px;
  height: 75%;
}

.order_Track_Step::after {
  bottom: 30px;
  height: 68%;
}

.order_Track_Step:first-child::before,
.order_Track_Step:first-child::after {
  display: none;
}

.order_Track_Step .step_Circle_Icon {
  width: 18px;
  height: 18px;
  border: 1px solid #cccccc;
  border-radius: 100%;
}

.order_Track_Step .step_Details {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.order_Track_Step .step_Details .step_Name {
  margin: 0;
  letter-spacing: -0.01em;
  color: #6d747a;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.order_Track_Step .step_Details .step_Date {
  margin: 0;
  letter-spacing: 0.004em;
  color: #6d747a;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.step_Completed::after,
.step_Completed::before {
  border-left: 2px solid #1b325e;
}

.order_Track_Wrapper .step_Completed:last-child {
  z-index: 100;
}

.step_Completed .step_Circle_Icon {
  border: 1px solid #1b325e;
  position: relative;
}

.step_Completed .step_Circle_Icon div {
  display: block;
  background: #1b325e;
  height: 9px;
  width: 9px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order_Track_Step.step_Completed .step_Details .step_Name {
  color: #000000;
}

.order_Cancel_Button {
  padding: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin: 0 0 0.75rem;
  text-decoration: none;
}

.order_Cancel_Button:hover p {
  color: #3d5176;
}

.order_Cancel_Button p {
  margin: 0;
  color: #3d5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/*Order Cancel CSS */
.order_Cancel_Container {
  padding: 30px 0.875rem;
  display: flex;
  flex-direction: column;
  max-width: max-content;
  margin: auto;
}

.order_Cancel_Container .option-text {
  /* font-family: 'Quicksand'; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: #707070;
}

.order_Cancel_Container .option-wrapper {
  padding: 12px 0.125rem;
}

.order_Cancel_Container div:nth-last-child(2),
.order_Cancel_Container div:nth-last-child(1) {
  border: none;
}

.order_Cancel_Submit {
  display: none;
}

.Accor-dot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: transparent;
  border: 1.5px solid rgb(69, 68, 68) !important;
  margin-right: 0.5rem;
}

.order_Details_Wrapper {
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(20px);
  background: linear-gradient(180.48deg, #f3f6ff 0.41%, rgba(243, 246, 255, 0.42) 132.2%);
  box-shadow: 0px 4px 20px rgba(27, 50, 94, 0.1);
  border-radius: 10px;
  margin-right: 14px;
  margin-left: 14px;
}

@media screen and (min-width: 768px) {
  .order_Details_Wrapper {
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(20px);
    background: linear-gradient(180.48deg, #f3f6ff 0.41%, rgba(243, 246, 255, 0.42) 132.2%);
    box-shadow: 0px 4px 20px rgba(27, 50, 94, 0.1);
    border-radius: 10px;
  }

  .order_Details_Header {
    margin: 1rem 0;
    padding: 0.875rem 1rem;
  }

  .header_Order_Status {
    margin: 0 0 0.25rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .header_Order_Number {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .order_Track_Container {
    gap: 1.5rem 1.5rem 2.188rem 1.5rem;
    margin-bottom: 2rem;
  }

  .order_Track_Wrapper {
    gap: 3.5rem;
  }

  .step_Details {
    gap: 0.25rem;
  }

  .order_Track_Step {
    gap: 1.5rem;
  }

  .order_Track_Step .step_Circle_Icon {
    width: 24px;
    height: 24px;
  }

  .order_Track_Step .step_Details .step_Name {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .order_Track_Step .step_Details .step_Date {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .order_Track_Step::after,
  .order_Track_Step::before {
    border-left: 4px dashed #cccccc;
    left: 10px;
  }

  .order_Track_Step::before {
    top: -71px;
    height: 80%;
  }

  .order_Track_Step::after {
    bottom: 38px;
    height: 92%;
  }

  .step_Completed::after,
  .step_Completed::before {
    border-left: 4px solid #1b325e;
  }

  .step_Completed .step_Circle_Icon div {
    height: 12px;
    width: 12px;
  }

  .order_Cancel_Button {
    max-width: 100%;
    padding: 0;
    background: transparent;
  }

  .order_Cancel_Button button {
    max-width: 100%;
  }

  .order_Cancel_Button p {
    color: #ffffff;
  }

  .order_Cancel_Button:hover p {
    color: #ffffff;
  }

  .order_Cancel_Container {
    margin: 1rem auto 2rem;
  }

  .order_Cancel_Submit {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .order_Cancel_Container .option-text {
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .order_Details_Wrapper {
    max-width: 885px;
  }

  .order_Details_Header {
    margin: 1.5rem 0 0.75rem;
    padding: 0.75rem 1.5rem;
  }
}
