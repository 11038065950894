.parentAddAddress {
    width: 100vw;
    padding: 19px;
    margin-top: var(--pageMarginTop);
    position: relative;
}

.headerSection {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 31px;

    color: #000000;
}

.breadcrumbs {
    font-size: 0.7rem;
}

.HeadingSent {
    font-size: 25px;
    margin-bottom: 20px;
}

.bodySection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addAddreesRightimg {
    width: 100%;
}

.inputAddress {
    width: 100%;
    cursor: pointer;
}

.instructionCss {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    cursor: pointer;
    color: #0066C0;
}

.deleteIcon:hover, 
.deleteIcon:focus-visible {
    -webkit-animation: ring 3.5s ease-in-out;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 3.5s ease-in-out;
    -moz-transform-origin: 50% 4px;
    animation: ring 3.5s ease-in-out;
    transform-origin: 50% 4px;
    animation-iteration-count: 3;
}

.no-address-found-text {
    font-size: 1rem;
}

.fromPayment-checkout-button {
    background-color: var(--primaryYellow)!important;
    padding: 1rem;
}

.fromPayment-checkout-text {
    font-size: 1.3rem;   
    font-weight: bold;
}

.fromPayment-checkout-button svg {
    margin-left: 0.5rem;
}

.fromPayment-section {
    position: sticky;
    bottom: 0;
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }

    1% {
        transform: rotate(30deg);
    }

    3% {
        transform: rotate(-28deg);
    }

    5% {
        transform: rotate(34deg);
    }

    7% {
        transform: rotate(-32deg);
    }

    9% {
        transform: rotate(30deg);
    }

    11% {
        transform: rotate(-28deg);
    }

    13% {
        transform: rotate(26deg);
    }

    15% {
        transform: rotate(-24deg);
    }

    17% {
        transform: rotate(22deg);
    }

    19% {
        transform: rotate(-20deg);
    }

    21% {
        transform: rotate(18deg);
    }

    23% {
        transform: rotate(-16deg);
    }

    25% {
        transform: rotate(14deg);
    }

    27% {
        transform: rotate(-12deg);
    }

    29% {
        transform: rotate(10deg);
    }

    31% {
        transform: rotate(-8deg);
    }

    33% {
        transform: rotate(6deg);
    }

    35% {
        transform: rotate(-4deg);
    }

    37% {
        transform: rotate(2deg);
    }

    39% {
        transform: rotate(-1deg);
    }

    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(0);
    }
}

@media screen and (max-width: 500px) {
    .address-breadcrumbs {
        font-size: 0.9rem!important;
        margin-bottom: 1rem;
    }
    .address_Footer_Edit {
        margin-top: -10px;
    }
}

@media screen and (min-width: 768px) {
    .parentAddAddress {
        width: 100vw;
        padding: 19px 153px;
    }

    .HeadingSent {
        font-size: 30px;
    }

    .bodySection {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .addAddreesRightimg {
        width: 100%;
        height: 450px;
    }

    .inputAddress {
        width: 95%;
        cursor: pointer;
    }
}